import React from 'react';

export const LinkToVideo = ({ to, title, imageSrc }) => (
  <div className="space-y-2">
    <a
      href={to}
      className="aspect-h-3 aspect-w-5 relative block w-full rounded-lg overflow-hidden focus:outline-none focus:ring focus:ring-primary-500 bg-primary-100"
    >
      {imageSrc ? (
        <img
          style={{ filter: 'brightness(0.8)' }}
          className="absolute h-full w-full object-cover"
          src={imageSrc}
          alt={title}
        />
      ) : (
        ''
      )}
      <div className="absolute inset-0 w-full h-full flex items-center justify-center">
        <svg className="h-12 w-12 text-primary-500" fill="currentColor" viewBox="0 0 84 84">
          <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
          <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
        </svg>
      </div>
    </a>
    <div className="text-lg font-medium">{title}</div>
  </div>
);
