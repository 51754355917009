import coteQuiChangent from '../images/thumbnails/developpement-durable/cotes-qui-changent.jpg';
import sosLacRose from '../images/thumbnails/developpement-durable/sos-lac-rose.jpg';
import petitsMetiers from '../images/thumbnails/developpement-durable/petits-metiers-debrouillards.jpg';
import cesPlastiques from '../images/thumbnails/developpement-durable/ces-plastiques.jpg';
import covid19AppuiMererue from '../images/thumbnails/covid-19/covid-19-mererue.jpg';
import covid19DonDeSang from '../images/thumbnails/covid-19/covid-19-don-de-sang.jpg';
import covid19FakeNews from '../images/thumbnails/covid-19/covid-19-fake-news.jpg';
import covid19Traitements from '../images/thumbnails/covid-19/covid-19-traitements.jpg';
import covid19Sensibilisation from '../images/thumbnails/covid-19/covid-19-sensibilisation.jpg';
import circoncisionMererue from '../images/thumbnails/circoncision-mererue.jpg';
import inaugurationInstitut from '../images/thumbnails/inauguration-institut.png';

export const videos = [
  {
    to: 'https://youtu.be/PgD0h5SAio0',
    imageSrc: inaugurationInstitut,
    title: 'Inauguration Institution Scolaire Amina Sow Mbaye',
  },
  {
    to: 'https://youtu.be/erDrWlfOWrU',
    imageSrc: circoncisionMererue,
    title: 'Circoncision à Mererue 2021',
  },
  {
    to: 'https://youtu.be/ouRfenqJBX8',
    imageSrc: coteQuiChangent,
    title: 'Des côtes qui changent et qui bougent.',
  },
  {
    to: 'https://youtu.be/xCs53ZCmefs',
    imageSrc: sosLacRose,
    title: 'SOS Lac Rose',
  },
  {
    to: 'https://youtu.be/ydMNKPvodm8',
    imageSrc: petitsMetiers,
    title: 'Petits métiers, Grands débrouillards',
  },
  {
    to: 'https://youtu.be/7nTsgwW75dE',
    imageSrc: cesPlastiques,
    title: 'Ces plastiques qui nous minent',
  },

  {
    to: 'https://youtu.be/e1oWqOEnluk',
    imageSrc: covid19AppuiMererue,
    title: 'COVID19 : Gestes barrières et Appui Mererue',
  },
  {
    to: 'https://youtu.be/C6Racz4grzM',
    imageSrc: covid19DonDeSang,
    title: 'COVID 19 : Plaidoyer pour le don du sang',
  },
  {
    to: 'https://youtu.be/j9UXpwGL55I',
    imageSrc: covid19FakeNews,
    title: 'COVID 19 : Fakenews et masques',
  },
  {
    to: 'https://youtu.be/GHoAmJVWVcU',
    imageSrc: covid19Traitements,
    title: 'COVID 19 : Traitement Attention',
  },
  {
    to: 'https://youtu.be/VHnLfrPNf1A',
    imageSrc: covid19Sensibilisation,
    title: 'COVID 19 : Sensibilisation, Information',
  },
];
