import React from 'react';
import PropTypes from 'prop-types';

export const Container = ({ children, className, maxWidth }) => {
  const sizes = {
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '7xl': 'max-w-7xl',
    container: 'container',
  };
  return <div className={`${sizes[maxWidth]} mx-auto px-4 sm:px-6 md:px-8 ${className}`.trim()}>{children}</div>;
};

Container.propTypes = {
  maxWidth: PropTypes.oneOf(['2xl', '3xl', '4xl', '7xl', 'container']).isRequired,
};

Container.defaultProp = {
  className: '',
};
